
import { defineComponent } from 'vue'
import { tabMenuItems } from './tab-menu-items'

export default defineComponent({
  setup () {
    return {
      tabMenuItems
    }
  }
})
