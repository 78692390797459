import { MenuItem } from 'primevue/menuitem'
import { EducationRouteNames } from '@/router/route-names'

export const tabMenuItems: MenuItem[] = [
  {
    label: 'Planificadas',
    to: {
      replace: true,
      name: EducationRouteNames.COURSES_PLANNING
    }
  },
  {
    label: 'Activas',
    to: {
      replace: true,
      name: EducationRouteNames.COURSES_PERFORMANCE
    }
  },
  {
    label: 'Finalizadas',
    to: {
      replace: true,
      name: EducationRouteNames.COURSES_FINISHED_PERFORMANCE
    }
  }
]
